(function() {
    'use strict';

    angular.module('adminApp')
        .component('aflAdminUserModalOrgRoleSection', {
            templateUrl: '/static/javascript/directives/afl-admin-user-modal/afl-admin-user-modal-org-role-section.html',
            controller: aflAdminUserModalOrgRoleSectionController,
            bindings: {
                orgName: '=',
                possibleOrgRoles: '=',
                isReadOnly: '=',
                selectedRoles: '='
            }
        });

    aflAdminUserModalOrgRoleSectionController.$inject = [];

    function aflAdminUserModalOrgRoleSectionController() {
        var $ctrl = this;

        angular.extend($ctrl, {
            isChecked: isChecked
        });

        function isChecked(role) {
            return $ctrl.selectedRoles.indexOf(role.id) >= 0;
        }
    }
})();
